<template>
  <div class="acknowledgement">
    <loading v-if="loading"/>
    <template v-else>
      <h2 class="page-title">撰写致谢</h2>
      <p>作品名称：{{book.title}}</p>
      <p>作者：{{book.author}}</p>
      <b-form-group label="致谢内容">
        <textarea class="form-control" rows="8" placeholder="此刻你最想感谢谁？（换行请使用回车）"
                  v-model="content" v-autosize v-autofocus v-word-count :maxlength="maxLength"/>
        <span class="lines">{{rows}} / {{maxRows}} 行</span>
      </b-form-group>

      <footer class="d-none d-sm-block">
        <b-btn variant="primary" block @click="save" :disabled="saving">
          <fa icon="spinner" spin v-if="saving"/>
          保存并返回
        </b-btn>
      </footer>

      <bottom-bar nav :items="navItems"/>
    </template>
  </div>
</template>

<script>
import bookUtils from '@/mixins/book-utils'
import routeData from '@/mixins/route-data'
import getLines from '@/utils/get-lines'

export default {
  name: 'acknowledgement',
  title: '撰写致谢',
  mixins: [routeData('book'), bookUtils],
  data() {
    return {
      saving: false,
      textWidth: 480,
      fontSize: 48,
      maxRows: 0,
      maxLength: 200,
      content: ''
    }
  },
  computed: {
    rows() {
      return getLines(this.content, this.textWidth, this.fontSize).rows
    },
    navItems() {
      return [{
        variant: 'primary',
        icon: 'check',
        text: '保存并返回',
        onClick: this.save
      }]
    }
  },
  methods: {
    onLoad() {
      this.content = this.book.acknowledgement || ''
      this.initConfig()
    },
    initConfig() {
      const typeset = this.book.type.split('-')[0].toLowerCase()
      const acknowledgement = this.book.style.acknowledgement || 'default'
      const templateName = 'wxbook-' + acknowledgement + '.acknowledgement-' + typeset
      return this.$req.get('https://canvas.xinshu.me/config/' + templateName).then(({layers}) => {
        const contentLayer = layers.find(i => i.name === 'content')
        this.textWidth = contentLayer.textWidth
        this.maxRows = contentLayer.rows
        this.fontSize = contentLayer.fontSize
      })
    },
    async save() {
      try {
        if (this.rows > this.maxRows) {
          return this.$alert.error('内容超过最大行数，请编辑到 ' + this.maxRows + ' 行以内再保存哦')
        }
        const acknowledgement = this.content || ''
        const data = {
          acknowledgement,
          type: this.book.type,
          bookType: this.book.bookType,
          sourceId: this.bookParams.sourceId
        }

        if (this.book.sourceTypeId) {
          data.sourceTypeId = this.book.sourceTypeId
        }
        this.saving = true
        if (this.book.bookType === 'blogbook') {
          // 博客书分开处理
          await this.$ajax.updateBlogbook({bid: this.book.id, data})
        } else {
          await this.$req.post('/api/book/design', data, {
            params: {
              book_type: this.book.bookType
            }
          })
        }
        this.$alert.success('致谢保存成功')
        this.$router.push('/books/' + this.$route.params.bookId + '#acknowledgement')
      } catch (err) {
        this.saving = false
      }
    }
  },
  created() {
    this.content = this.book.acknowledgement
  }
}
</script>

<style scoped lang="scss">
  .acknowledgement {
    max-width: 480px;

    textarea {
      padding-bottom: 2em;
    }

    .lines {
      float: left;
      font-size: 12px;
      margin-top: -2em;
      margin-left: 1em;
      color: $text-muted;
      display: inline-block;
      pointer-events: none;
    }
  }
</style>
