<template>
  <div class="date-time-picker">
    <b-input-group ref="inputGroup">
      <input type="date" class="form-control" min="1970-01-01" max="2029-12-31" v-model="date">
      <input type="time" v-model="time" class="form-control" style="max-width: 8.5em;">
    </b-input-group>
    <b-tooltip :target="() => this.$refs.inputGroup">
      {{!isBeijingTime ? '北京时间' : ''}}
      <datetime :value="value" full/>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'dateTimePicker',
  data() {
    return {
      isBeijingTime: new Date().getTimezoneOffset() === -480,
      date: this.$day().utcOffset(8).format('YYYY-MM-DD'),
      time: this.$day().utcOffset(8).format('HH:mm')
    }
  },
  props: {
    value: [String, Number]
  },
  mounted() {
    if (!this.value) {
      this.syncModel()
      return
    }
    const date = this.$day(this.value).utcOffset(8)
    this.date = date.format('YYYY-MM-DD')
    this.time = date.format('HH:mm')
  },
  watch: {
    date: 'syncModel',
    time: 'syncModel'
  },
  methods: {
    syncModel() {
      if (this.date && !this.$day(this.date).isValid()) {
        this.$emit('input', null)
        return
      }

      if (!this.time) {
        this.$emit('input', null)
        return
      }

      if (this.date && this.time) {
        const date = this.date.split('-').join('/')
        const time = this.time
        const dateTime = this.$day(new Date(date + ' ' + time + ' +0800')).startOf('minute')
        const value = dateTime.toJSON()
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.date-time-picker {
  input[type=time]::-webkit-clear-button,
  input[type=date]::-webkit-clear-button,
  input[type=date]::-webkit-outer-spin-button,
  input[type=date]::-webkit-inner-spin-button,
  input[type=time]::-webkit-outer-spin-button,
  input[type=time]::-webkit-inner-spin-button {
    display: none;
  }
}
</style>
