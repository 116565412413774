<template>
  <div class="restore">
    <b-card class="select-bar" v-headroom>
      <b-row align-v="center" v-if="selecting">
        <b-col cols="auto">
          <span style="cursor: pointer; user-select: none;"
                :class="{'text-primary': allChecked}"
                @click="allChecked = !allChecked">
            <fa icon="check-circle" v-if="allChecked"/>
            <fa icon="circle" far class="text-muted" v-else/>
            全选
          </span>
        </b-col>
        <b-col>
          已选 <span :class="{'text-primary': selected.length}">{{selected.length}}</span> 条
        </b-col>
        <b-col cols="auto">
          <b-link :disabled="saving || !selected.length" @click="restoreSelected">
            <fa icon="spinner" spin v-if="saving"/>
            <fa icon="recycle" v-else/>
            恢复已选
          </b-link>
          &#12288;
          <b-link :disabled="saving" @click="cancelSelect">取消</b-link>
        </b-col>
      </b-row>

      <b-row align-v="center" v-else-if="searching">
        <b-col>
          <b-input-group>
            <b-select v-model="exclude" style="max-width: 5em;">
              <option :value="false">包含</option>
              <option :value="true">不包含</option>
            </b-select>
            <b-input placeholder="按文本筛选" v-model="keyword" trim autofocus/>
          </b-input-group>
        </b-col>
        <b-col cols="auto" class="pl-0">
          <b-btn @click="reset">取消</b-btn>
          <b-btn @click="selecting = true" variant="primary" :disabled="!filteredItems.length">
            批量恢复
          </b-btn>
        </b-col>
      </b-row>

      <b-row align-v="center" v-else>
        <b-col>
          <b>正在恢复{{$route.params.month + '01' | moment('YYYY年MM月')}}</b>
        </b-col>
        <b-col cols="auto" v-if="!loading">
          <b-link class="mr-2" @click="searching = true">
            <fa icon="search"/>
            搜索
          </b-link>
          <b-link @click="selecting = !selecting">
            <fa icon="list-alt"/>
            批量恢复
          </b-link>
        </b-col>
      </b-row>
    </b-card>

    <template v-if="loading">
      <b-card v-for="i in 2" :key="'ske' + i" no-body>
        <b-card-header class="border-bottom-0">
          <ske width="7em"/>
        </b-card-header>
        <b-card-body>
          <p>
            <ske block width="8em"/>
            <ske block/>
          </p>

          <grid cols="4">
            <grid-item>
              <ske type="square"/>
            </grid-item>
            <grid-item>
              <ske type="square"/>
            </grid-item>
          </grid>
        </b-card-body>
      </b-card>
    </template>

    <template v-else>
      <div class="side-menu float-right">
        <side-menu :items="navs" v-headroom="'1rem'"/>
      </div>

      <div class="side-menu float-left">
        <month-nav :months="restoreMonths" v-headroom="'1em'" :route="$route"/>
      </div>

      <div class="list" v-if="filteredItems.length">
        <b-card v-for="item in filteredItems" :key="item.id"
                :class="{selected: selected.includes(item), selecting}"
                @click="toggleSelect(item)">
          <template slot="header">
            <datetime :value="item.time" full/>
            <div class="float-right">
              <template class="text-muted" v-if="selecting">
                <div class="text-muted" v-if="!selected.includes(item)">点击任意位置选中</div>
                <div class="text-primary" v-else>
                  <fa icon="check"/>
                  已选中
                </div>
              </template>
              <b-link @click="restoreItem(item)" v-else>
                <fa icon="recycle"/>
                恢复
              </b-link>
            </div>
          </template>

          <div class="text" v-if="item.text" @click="restoreText(item)">{{item.text}}</div>
          <image-zone class="mb-0" disable-preview @image-click="restoreImage" contain
                      :value="item.pics" v-if="item.pics.length"/>
        </b-card>
      </div>

      <b-card class="text-center" v-else>
        <p>
          <img src="https://static.weixinshu.com/assets/images/deer/wail.png!320"
               style="max-width: 50%; width: 150px;">
        </p>
        <div>
          <template v-if="keyword">
            <div>这里没有任何与 <b>{{keyword}}</b> 相关内容哦</div>
            <div>换个词筛选试试吧</div>
          </template>
          <template v-else-if="restoreMonths && restoreMonths.length">
            <b>当前月份（{{$route.params.month}}）没有可恢复的内容哦</b>
            <br>
            换个月份试试吧
          </template>
          <template v-else>
            <b>这里没有任何可恢复的内容哦</b>
            <br>
            已隐藏的月份请进入
            <router-link :to="'/books/' + bookId + '/months'">选择月份</router-link>
            页面取消隐藏
          </template>
        </div>
      </b-card>

      <footer class="mt-3">
        <b-row>
          <b-col v-if="prevMonth">
            <b-btn :to="'./' + prevMonth.route" block>
              <fa icon="angle-left"/>
              {{prevMonth.value}}
            </b-btn>
          </b-col>
          <b-col v-if="nextMonth">
            <b-btn :to="'./' + nextMonth.route" block>
              {{nextMonth.value}}
              <fa icon="angle-right"/>
            </b-btn>
          </b-col>
          <b-col v-else>
            <b-btn block disabled>
              后面没有啦
              <fa icon="paragraph"/>
            </b-btn>
          </b-col>
        </b-row>
      </footer>

      <month-popup :months="restoreMonths" v-if="popup" @close="popup = false" :book="book"/>

      <bottom-bar :items="navs" nav/>
    </template>
  </div>
</template>

<script>
import { includes, pull } from 'lodash'
import ImageZone from '@/components/ImageZone'
import MonthNav from '@/components/MonthNav'
import MonthPopup from '@/components/MonthPopup'

import previewImage from '@/mixins/preview-image'
import bookUtils from '@/mixins/book-utils'
import routeData from '@/mixins/route-data'
import SideMenu from '@/components/SideMenu'

export default {
  name: 'restore',
  title: '恢复内容',
  mixins: [
    routeData('book', {relativeParam: 'bookId'}),
    routeData('restoreItems', {relativeParam: 'month'}),
    routeData('restoreMonths', {relativeParam: 'bookId'}),
    bookUtils,
    previewImage
  ],
  components: {SideMenu, ImageZone, MonthNav, MonthPopup},
  data() {
    return {
      selecting: false,
      selected: [],

      exclude: false,
      searching: false,
      keyword: '',

      saving: false,
      popup: false
    }
  },
  computed: {
    navs() {
      return [
        {
          title: '目录',
          icon: 'bars',
          maxWidth: '5em',
          divide: true,
          exclusive: 'nav',
          onClick: () => {
            this.popup = true
          }
        }, {
          title: '编辑内容',
          icon: 'pencil',
          to: '/books/' + this.bookId + '/edit/' +
            (this.$route.params.month || this.book.firstMonth)
        }, {
          hr: 'bottom',
          title: '预览',
          icon: 'book',
          variant: 'primary',
          disabled: this.saving,
          to: '/books/' + this.bookId + '/months/' + this.$route.params.month
        }
      ]
    },
    allChecked: {
      get() {
        return this.selected.length === this.restoreItems.length
      },
      set(val) {
        if (val) {
          this.selected.push(...this.filteredItems)
        } else {
          this.selected = []
        }
      }
    },
    filteredItems() {
      if (!this.searching || !this.keyword) {
        return this.restoreItems
      }
      return this.restoreItems.filter(i => this.exclude ^ includes(i.text, this.keyword))
    },
    prevMonth() {
      return this.restoreMonths.prev(this.$route.params.month)
    },
    nextMonth() {
      return this.restoreMonths.next(this.$route.params.month)
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.popup = false
    next()
  },
  methods: {
    onLoad() {
      this.selected = []
    },
    isEmpty(item) {
      return !item.text && (!item.pics || item.pics.length === 0)
    },
    reset() {
      this.searching = false
      this.keyword = ''
    },

    toggleSelect(item) {
      if (!this.selecting) {
        return
      }
      if (this.selected.indexOf(item) === -1) {
        this.selected = this.selected.concat(item)
      } else {
        this.selected = this.selected.filter(i => i !== item)
      }
    },
    async restoreSelected() {
      try {
        if (!this.selected.length) {
          return this.$alert.error('不能恢复空内容哦')
        }
        const confirmed = await this.$dialog.confirm({
          title: '恢复内容',
          content: `是否批量恢复已选的 ${this.selected.length} 条内容`
        })

        if (!confirmed) {
          return
        }

        this.saving = true

        const recIds = []
        for (const item of this.selected) {
          recIds.push(item.id)
          if (item.pics) {
            recIds.push(...item.pics.map(p => p.id))
          }
        }
        await this.restore(recIds.filter(Boolean))
        this.$alert.success(`已选 ${this.selected.length} 条内容恢复成功`)
        this.restoreItems = this.restoreItems.filter(i => !recIds.includes(i.id))

        this.selected = []
      } catch (err) {
      }
      this.saving = false
    },
    cancelSelect() {
      this.selected = []
      this.selecting = false
    },

    async restoreImage(pic) {
      if (this.selecting) {
        return
      }
      const item = this.restoreItems.filter(i => i.pics).find(i => i.pics.includes(pic))
      const confirmed = await this.$dialog.confirm({
        title: '恢复内容',
        content: '是否恢复该图片'
      })
      if (!confirmed) {
        return
      }
      return this.restore([pic.id]).then(() => {
        this.$alert.success('图片恢复成功')
        item.pics.splice(item.pics.indexOf(pic), 1)
        if (this.isEmpty(item)) {
          pull(this.restoreItems, item)
        }
      })
    },
    async restoreText(item) {
      if (this.selecting) {
        return
      }
      const confirmed = await this.$dialog.confirm({
        title: '恢复内容',
        content: '是否单独恢复文字内容'
      })
      if (!confirmed) {
        return
      }
      return this.restore([item.id]).then(() => {
        this.$alert.success('文字内容恢复成功')
        item.text = ''
        if (this.isEmpty(item)) {
          this.restoreItems.splice(this.restoreItems.indexOf(item), 1)
        }
      })
    },
    async restoreItem(curItem) {
      if (this.selecting) {
        return
      }
      const confirmed = await this.$dialog.confirm({
        title: '恢复内容',
        content: '是否恢复本条内容'
      })
      if (!confirmed) {
        return
      }
      const recIds = [curItem.id]

      if (curItem.pics) {
        recIds.push(...curItem.pics.map(pic => pic.id))
      }

      return this.restore(recIds).then(() => {
        this.$alert.success('内容恢复成功')
        this.restoreItems.splice(this.restoreItems.indexOf(curItem), 1)
      })
    },
    restore(recIds) {
      const {sourceId, sourceTypeId} = this.bookParams

      const data = {
        recIds,
        action: 'recover',
        sourceId,
        bookType: this.book.bookType,
        month: this.$route.params.month
      }

      if (sourceTypeId) {
        data.sourceTypeId = sourceTypeId
      }

      this.saving = true

      return this.$req.post('/api/book/design/message', data).then(data => {
        this.saving = false
        return data
      }).catch(err => {
        this.saving = false
        return Promise.reject(err)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.restore {
  max-width: 640px;
  position: relative;

  .select-bar {
    @include clearfix();
    margin-bottom: 1em;

    &.headroom {
      z-index: 20;
      top: 0;
      border-top: 0;
      width: 100%;
      max-width: 620px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      @include media-breakpoint-down(sm) {
        left: 0;
      }
    }
  }

  .text {
    white-space: pre-wrap;
    cursor: pointer;
  }

  .list {
    time {
      font-weight: bold;
      color: $text-muted;
    }
  }

  .card {
    &.selecting {
      cursor: pointer;
    }

    &.selected {
      border-color: $primary;
    }
  }

  .card-header {
    border-bottom: 0;
  }
}
</style>
