<template>
  <div class="month-selection">
    <loading v-if="loading"/>
    <template v-else-if="months && months.length">
      <div class="float-right" v-if="!isSmallScreen">
        <side-menu :items="navs" v-headroom="'1rem'"/>
      </div>
      <b-card>
        <b-card-title class="clearfix">
          <b>筛选月份</b>
          <div class="small float-right">
            已选 {{keptMonths.length}} 个月份，点击年份操作整年
          </div>
        </b-card-title>

        <b-row v-for="(group, year) in months" :key="year">
          <b-col class="pr-0 pt-3" cols="auto"
                 @click="selectYear(year, group.every(s => s.hidden))">
            <b class="year">{{year}}年</b>
          </b-col>
          <b-col>
            <grid cols="6" sm="12">
              <grid-item v-for="item in group" :key="item.route" padding="2px">
                <square :selected="!item.hidden" border @click="selectMonth(item)" class="select">
                  {{item.month}}
                  <div class="checked" slot="check">
                    <fa icon="check"/>
                  </div>
                </square>
              </grid-item>
            </grid>
          </b-col>
        </b-row>
      </b-card>

      <b-card v-if="book.canUpdate" title="更新内容">
        <div class="mb-2">
          <b>内容不全？缺少最新的内容？</b>
        </div>
        <b-btn block disabled v-if="book.updating">
          <fa icon="spinner" spin/>
          正在更新，请稍后回来
        </b-btn>
        <template v-else>
          <b-btn to="./update" block>
            <fa icon="sync"/>
            <span v-if="book.bookType === 'wbbook'">同步最新微博</span>
            <span v-if="book.bookType === 'shuoshuo_book'">同步最新动态</span>
            <span v-if="book.bookType === 'wxbook'">同步最新朋友圈</span>
          </b-btn>
        </template>
      </b-card>

      <bottom-bar nav :items="navs"/>
    </template>

    <b-card class="text-center" v-else>
      <img src="https://static.weixinshu.com/assets/images/deer/study.png" style="width: 240px;">
      <p>对不起，您的作品没有任何内容哦，去增加些内容吧</p>
      <div class="btn-area">
        <b-btn block :to="'/books/' + $route.params.bookId + '/edit/' + $day().format('YYYYMM')">
          <fa icon="plus-circle"/>
          补写内容
        </b-btn>
      </div>
    </b-card>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'
import routeData from '@/mixins/route-data'
import bookUtils from '@/mixins/book-utils'
import SideMenu from '@/components/SideMenu'

export default {
  name: 'monthSelection',
  components: {SideMenu},
  title: '选择月份',
  mixins: [
    routeData('book'),
    routeData('months', {query: {all: true}}),
    bookUtils
  ],
  data() {
    return {
      saving: false,
      originalData: {
        keptMonths: [],
        deletedMonths: []
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta?.bookParams?.bookType === 'star-wbbook') {
      return location.replace(`https://weiboshu.com/design/${to.meta.bookParams.id}/months`)
    }
    next()
  },
  async beforeRouteLeave(to, from, next) {
    if (this.dirty) {
      const confirmed = await this.$dialog.confirm({
        content: '您对月份的修改尚未保存，是否需要立刻保存？',
        okTitle: '保存并离开',
        cancelTitle: '直接离开'
      })
      if (confirmed) {
        await this.save()
      }
    }
    next()
  },
  computed: {
    keptMonths() {
      return this.months.plain().filter(i => !i.hidden)
    },
    deletedMonths() {
      return this.months.plain().filter(i => i.hidden)
    },
    dirty() {
      return !isEqual(this.originalData, {
        keptMonths: this.keptMonths,
        deletedMonths: this.deletedMonths
      })
    },
    navs() {
      const {editable} = this.book
      const hasContent = this.book.chapters

      return [
        {
          icon: 'palette',
          title: '设计样式',
          tooltip: '修改作品封面、版式及插页等样式',
          to: '/books/' + this.bookId + '/design',
          show: editable
        },
        {
          icon: hasContent ? 'pencil' : 'plus-circle',
          title: hasContent ? '编辑内容' : '补写内容',
          tooltip: hasContent ? '对作品内容进行添加、修改' : '为作品添加内容',
          to: '/books/' + this.bookId + '/edit',
          show: editable
        },
        {
          title: '保存并预览',
          icon: 'check',
          disabled: this.saving,
          onClick: this.onSubmit,
          variant: 'primary',
          hr: 'bottom'
        }
      ]
    }
  },
  methods: {
    onLoad() {
      this.originalData = {
        keptMonths: cloneDeep(this.keptMonths),
        deletedMonths: cloneDeep(this.deletedMonths)
      }
    },
    selectYear(year, doHide) {
      const months = this.months[year]
      months.forEach(i => {
        i.hidden = !doHide
      })
    },
    selectMonth(item) {
      const doHide = !item.hidden
      if (doHide && this.months.plain().filter(i => !i.hidden).length <= 1) {
        this.$alert.error('不能删除最后的一个月哦')
        return
      }
      item.hidden = doHide
    },

    async save() {
      if (this.keptMonths.length < 1) {
        this.$alert.warn('至少要选择一个月份哦')
        return
      }

      const data = Object.assign({}, {
        type: this.book.type,
        bookType: this.book.bookType,
        keptMonths: this.keptMonths.map(i => i.route),
        deletedMonths: this.deletedMonths.map(i => i.route),
        sourceId: this.bookParams.sourceId,
        sourceType: this.book.sourceType,
        sourceTypeId: this.book.sourceTypeId
      })

      try {
        this.saving = true
        await this.$req.post('/api/book/design/screen_months', data)
        this.onLoad()
      } finally {
        this.saving = false
      }
    },

    async onSubmit() {
      await this.save()
      this.$router.push('/books/' + this.bookId)
    }
  }
}
</script>

<style scoped lang="scss">
.month-selection {
  max-width: 640px;

  .year {
    cursor: pointer;
    user-select: none;
  }

  .select {
    text-align: center;
    user-select: none;

    .checked {
      position: absolute;
      top: 0;
      right: 0;
      color: $white;
      font-size: 12px;
      line-height: 1;
      padding: 1px;
      padding-left: 12px;
      padding-bottom: 12px;
      background-color: $primary;
      background: linear-gradient(45deg, transparent 18px, $primary 0);
      transform-origin: top right;
      transform: scale(0.83333333333);
      display: none;
    }

    &.selected {
      color: $primary;
      border-color: $primary !important;
      box-shadow: 0 0 1px 0 currentColor;

      .checked {
        display: block;
      }
    }
  }

  .update-container {
    text-align: center;
    border-radius: $border-radius;
  }
}
</style>
