<template>
  <div class="edit">
    <b-card class="select-bar" v-headroom style="line-height: 33px;">
      <b-row align-v="center" v-if="selecting">
        <b-col cols="auto">
          <span style="cursor: pointer; user-select: none;" :class="{'text-primary': allChecked}"
                @click="allChecked = !allChecked">
            <fa icon="check-circle" v-if="allChecked"/>
            <fa icon="circle" far class="text-muted" v-else/>
            全选
          </span>
        </b-col>
        <b-col>
          已选
          <span :class="{'text-primary': selected.length}">{{selected.length}}</span>
          条
        </b-col>
        <b-col cols="auto">
          <b-link :disabled="saving || !selected.length" @click="deleteSelected">
            <fa icon="spinner" spin v-if="saving"/>
            <fa icon="trash" v-else/>
            删除已选
          </b-link>
          &#12288;
          <b-link :disabled="saving" @click="cancelSelect">取消</b-link>
        </b-col>
      </b-row>

      <b-row align-v="center" v-else-if="searching">
        <b-col>
          <b-input-group>
            <b-select v-model="exclude" style="max-width: 5em;">
              <option :value="false">包含</option>
              <option :value="true">不包含</option>
            </b-select>
            <b-input placeholder="按文本筛选" v-model="keyword" trim autofocus/>
          </b-input-group>
        </b-col>
        <b-col cols="auto" class="pl-0">
          <b-btn @click="reset">取消</b-btn>
          <b-btn @click="selecting = true" variant="primary" :disabled="!filteredItems.length">
            批量删除
          </b-btn>
        </b-col>
      </b-row>

      <b-row align-v="center" v-else>
        <b-col>
          <b>
            正在编辑
            <template v-if="$route.params.month">
              {{$route.params.month + '05' | moment('YYYY年MM月')}}
            </template>
          </b>
        </b-col>
        <b-col cols="auto" v-if="bookItems && bookItems.length">
          <b-link class="mr-2" @click="searching = true">
            <fa icon="search"/>
            搜索
          </b-link>
          <b-link @click="selecting = true">
            <fa icon="list-alt"/>
            批量删除
          </b-link>
        </b-col>
      </b-row>
    </b-card>

    <template v-if="loading">
      <b-card v-for="i in 2" :key="'ske' + i" no-body>
        <b-card-header class="border-bottom-0">
          <ske width="7em"/>
        </b-card-header>
        <b-card-body>
          <p>
            <ske block/>
            <ske block/>
          </p>

          <grid cols="4">
            <grid-item>
              <ske type="square"/>
            </grid-item>
            <grid-item>
              <ske type="square"/>
            </grid-item>
          </grid>
        </b-card-body>
      </b-card>
    </template>

    <template v-else>
      <div class="side-menu float-left">
        <month-nav :months="months" v-headroom="'1em'" :route="$route"/>
      </div>
      <div class="side-menu float-right">
        <side-menu :items="navs" v-headroom="'1rem'"/>
      </div>

      <div class="book-items" v-if="filteredItems.length">
        <content-item :key="item.id" :disabled="!item.id"
                      :selected="selected.indexOf(item) > -1"
                      :selecting="selecting" :value="item"
                      @click.native="toggleSelect(item)"
                      @save="onSave"
                      @delete="onDelete"
                      @previewImage="$previewImage"
                      @preview-video="previewVideo"
                      v-for="item in filteredItems"/>
      </div>

      <b-card class="text-center" v-else>
        <p>
          <img src="https://static.weixinshu.com/assets/images/deer/wail.png!320" alt
               style="max-width: 50%; width: 150px;">
        </p>
        <template v-if="keyword">
          <div>这里没有任何与 <b>{{keyword}}</b> 相关内容哦</div>
          <div>换个词筛选试试吧</div>
        </template>
        <template v-else>
          <b>这里没有任何内容哦</b>
          <br>
          换个月份试试吧
        </template>
      </b-card>

      <footer class="mt-3">
        <b-row>
          <b-col v-if="prevMonth">
            <b-btn :to="'./' + prevMonth.route" block>
              <fa icon="angle-left"/>
              {{prevMonth.value}}
            </b-btn>
          </b-col>
          <b-col v-if="nextMonth">
            <b-btn :to="'./' + nextMonth.route" block>
              {{nextMonth.value}}
              <fa icon="angle-right"/>
            </b-btn>
          </b-col>
          <b-col v-else>
            <b-btn block disabled>
              后面没有啦
              <fa icon="paragraph"/>
            </b-btn>
          </b-col>
        </b-row>
      </footer>

      <template v-if="isSmallScreen">
        <month-popup :months="months" @close="popup = false" v-if="popup" :book="book"/>
        <bottom-bar :items="navs" nav/>
      </template>

      <write v-model="dialogActive" @save="setNewItem"></write>

      <b-modal id="videoPreview" title="视频预览" @hidden="video = null" hide-footer>
        <video-player height="100%" :src="video.src" :poster="video.poster" v-if="video"/>
      </b-modal>
    </template>
  </div>
</template>

<script>
import { includes, without } from 'lodash'
import ContentItem from '@/components/ContentItem'
import MonthNav from '@/components/MonthNav'
import MonthPopup from '@/components/MonthPopup'
import VideoPlayer from '@/components/VideoPlayer'
import Write from '@/components/Write'

import bookUtils from '@/mixins/book-utils'
import previewImage from '@/mixins/preview-image'
import routeData from '@/mixins/route-data'
import SideMenu from '@/components/SideMenu'

export default {
  name: 'edit',
  title: '编辑内容',
  mixins: [
    routeData('book', {relativeParam: 'bookId'}),
    routeData('months', {relativeParam: 'bookId'}),
    routeData('bookItems', {relativeParam: 'month'}),
    bookUtils,
    previewImage
  ],
  components: {SideMenu, MonthNav, MonthPopup, Write, VideoPlayer, ContentItem},
  beforeRouteEnter(to, from, next) {
    if (to.params.bookId.includes('OmJsb2dib29r')) {
      // 博客书判断，判断字符串为 ':blogbook'
      return next('/books/' + to.params.bookId + '/catalog')
    }
    next()
  },
  beforeRouteUpdate(to, from, next) {
    this.popup = false
    next()
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.$el.querySelector('.content-item.editing')) {
      return next()
    }
    const confirmed = await this.$dialog.confirm({
      title: '未保存的内容',
      content: '您有未保存的内容，是否忽略未保存的内容并离开',
      okTitle: '忽略并离开',
      cancelTitle: '留下'
    })
    if (!confirmed) {
      return
    }
    next()
  },
  data() {
    return {
      saving: false,
      saved: false,

      exclude: false,
      searching: false,
      keyword: '',

      selecting: false,
      selected: [],

      writing: false,
      popup: false,
      dialogActive: false,
      newItems: [],

      showVideo: false,
      video: null
    }
  },
  computed: {
    navs() {
      return [
        {
          title: '目录',
          icon: 'bars',
          onClick: () => {
            this.popup = true
          },
          maxWidth: '5em',
          exclusive: 'nav',
          divide: true
        },
        {
          title: '补写内容',
          icon: 'plus-circle',
          tooltip: '向作品添加新内容',
          desc: '点此添加内容哦',
          hr: 'top',
          onClick: () => {
            this.dialogActive = true
          }
        },
        {
          title: '筛选月份',
          icon: 'calendar-alt',
          disabled: !this.book.chapters,
          tooltip: '显示或隐藏作品月份内容',
          exact: true,
          to: '/books/' + this.bookId + '/months',
          show: this.book.editable
        },
        {
          title: '回收箱',
          icon: 'trash-restore',
          to: '../restore/' + this.$route.params.month,
          tooltip: '找回已删除的内容',
          disabled: this.saving
        },
        {
          title: this.saved ? '已保存' : '预览',
          hr: 'bottom',
          tooltip: '返回预览当前月份内容',
          icon: this.saved ? 'check' : 'book',
          to: '../months/' + this.$route.params.month,
          variant: 'primary',
          disabled: this.saved
        }
      ]
    },
    backUrl() {
      if (!this.bookItems || this.bookItems.length === 0) {
        return '/books/' + this.bookId
      }
      return '/books/' + this.bookId + '/months/' + this.$route.params.month
    },
    prevMonth() {
      return this.months.prev(this.$route.params.month)
    },
    nextMonth() {
      return this.months.next(this.$route.params.month)
    },
    allChecked: {
      get() {
        return this.selected.length === this.bookItems.length
      },
      set(val) {
        this.selected = []
        if (val) {
          this.selected.push(...this.filteredItems)
        }
      }
    },
    filteredItems() {
      if (!this.searching || !this.keyword) {
        return this.bookItems
      }
      return this.bookItems.filter(i => this.exclude ^ includes(i.text, this.keyword))
    }
  },
  methods: {
    onLoad() {
      if (!this.$route.params.month) {
        const month = this.book.firstMonth || this.$day().format('YYYYMM')
        return this.$router.replace({
          path: '/books/' + this.$route.params.bookId + '/edit/' + month,
          query: {...this.$route.query}
        })
      }
      this.selected = []
      this.newItems = []
      if (!this.bookItems.length || this.$route.query.write) {
        this.dialogActive = true
      }
    },
    async onDelete(item) {
      this.bookItems = without(this.bookItems, item)
    },
    onSave() {
      this.saved = true
      setTimeout(() => {
        this.saved = false
      }, 1000)
    },

    toggleSelect(item) {
      if (!this.selecting) {
        return
      }
      if (this.selected.indexOf(item) === -1) {
        this.selected = this.selected.concat(item)
      } else {
        this.selected = this.selected.filter(i => i !== item)
      }
    },
    async deleteSelected() {
      try {
        if (!this.selected.length) {
          return this.$alert.error('不能删除空内容哦')
        }
        const confirmed = await this.$dialog.confirm({
          title: '删除内容',
          content: `是否批量删除已选的 ${this.selected.length} 条内容`
        })

        if (!confirmed) {
          return
        }

        this.saving = true

        const ids = this.selected.map(item => item.id)

        const data = this.forgeData('batch_delete', {
          deleteMainItemIds: ids
        })

        await this.$req.post('/api/book/design/message', data)

        this.$alert.success('内容删除成功')

        this.bookItems = this.bookItems.filter(item => this.selected.indexOf(item) === -1)
        this.selected = []
      } catch (err) {
      }
      this.saving = false
    },
    cancelSelect() {
      this.selected = []
      this.selecting = false
    },

    reset() {
      this.searching = false
      this.keyword = ''
    },

    forgeData(action, data) {
      const {sourceId, sourceTypeId} = this.bookParams

      data = Object.assign({}, {
        action,
        month: this.$route.params.month,
        bookType: this.book.bookType,
        sourceId,
        sourceTypeId,
        sourceType: this.book.sourceType
      }, data)

      return data
    },
    setNewItem(item) {
      const month = this.$day(item.time).format('YYYYMM')
      if (month !== this.$route.params.month) {
        // 补写到非当前月份的直接跳转到补写的月份
        return this.$router.push('./' + month + '?msgId=' + item.id)
      } else {
        window.scrollTo(0, 0)
      }
      this.bookItems.unshift(item)
    },

    previewVideo({src, poster}) {
      this.video = {
        src: 'https://media2.xinshu.me/fetch/' + this.$base64encode(src),
        poster: this.$img(poster)
      }
      this.$bvModal.show('videoPreview')
    },
    clearVideo() {
      this.video = {}
      this.$bvModal.hide('videoPreview')
    }
  },
  watch: {
    showVideo(val) {
      if (!val) {
        this.clearVideo()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.edit {
  max-width: 640px;
  position: relative;

  .select-bar {
    @include clearfix();
    margin-bottom: 1em;

    &.headroom {
      z-index: 20;
      top: 0;
      border-top: 0;
      width: 100%;
      max-width: 620px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      @include media-breakpoint-down(sm) {
        left: 0;
      }

      ~ .book-items {
        margin-top: 72px;
      }
    }
  }

  .list {
    header {
      margin-bottom: .5em;
    }
  }
}
</style>
