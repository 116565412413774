<template>
  <div :class="[book ? book.type : '']" class="preface">
    <loading v-if="loading"/>
    <template v-else>
      <h2 class="page-title">撰写序言</h2>
      <p>作品名称：{{book.title}}</p>
      <p>作者：{{book.author}}</p>
      <b-form-group>
        <template slot="label">
          序言内容
          <b-link class="float-right" @click="deIndent" v-if="hasIndent">删除缩进</b-link>
          <b-link class="float-right" @click="indent" v-else>
            <fa icon="indent"/>
            缩进两格
          </b-link>
        </template>
        <textarea class="form-control" id="preface" :maxlength="maxLength"
                  placeholder="给你的书写个序吧..."
                  rows="10" v-autofocus v-autosize v-model="content" v-word-count></textarea>
        <span class="lines">{{rows}} / {{maxRows}} 行</span>
      </b-form-group>

      <b-form-group label="署名（右对齐，最多 20 字）">
        <b-input maxlength="20" placeholder="点击添加署名（如：爱你的妈妈）" v-model="signature"/>
      </b-form-group>

      <footer class="d-none d-sm-block">
        <b-btn @click="save" block variant="primary" :disabled="saving">
          <fa icon="spinner" spin v-if="saving"/>
          保存并返回
        </b-btn>
      </footer>

      <bottom-bar :items="navItems" nav/>
    </template>
  </div>
</template>

<script>
import { trim } from 'lodash'

import bookUtils from '@/mixins/book-utils'
import routeData from '@/mixins/route-data'
import getLines from '@/utils/get-lines'

export default {
  name: 'preface',
  title: '撰写序言',
  mixins: [routeData('book'), bookUtils],
  data() {
    return {
      saving: false,
      content: '',
      signature: '',
      textWidth: 1640,
      maxRows: 0,
      fontSize: 48
    }
  },
  computed: {
    maxLength() {
      if (this.maxRows && this.rows > this.maxRows) {
        return 1
      }
      return /A4/.test(this.book.type) ? 1000 : 500
    },
    hasIndent() {
      return this.content.split('　').length > 2
    },
    navItems() {
      return [
        {
          variant: 'primary',
          icon: 'check',
          text: '保存并返回',
          onClick: this.save
        }
      ]
    },
    rows() {
      return getLines(this.content, this.textWidth, this.fontSize).rows
    }
  },
  methods: {
    onLoad() {
      const [content, signature] = (this.book.preface || '').split('#signature#')
      this.content = content || ''
      this.signature = signature || ''
      this.initConfig()
    },
    deIndent() {
      this.content = this.content.split(/\r?\n/).map(p => trim(p, '　')).join('\n')
    },
    indent() {
      this.content = this.content.split(/\r?\n/).map(p => trim(p) ? '　　' + trim(p) : '').join('\n')
    },
    initConfig() {
      const typeset = this.book.type.split('-')[0].toLowerCase()
      const preface = this.book.style.preface || 'default'
      const templateName = 'wxbook-' + preface + '.preface-' + typeset
      return this.$req.get('https://canvas.xinshu.me/config/' + templateName)
        .then(({layers}) => {
          const contentLayer = layers.find(i => i.name === 'content')
          this.textWidth = contentLayer.textWidth
          this.maxRows = contentLayer.rows
          this.fontSize = contentLayer.fontSize
        })
    },
    async save() {
      if (this.rows > this.maxRows) {
        return this.$alert.error('内容超过最大行数，请编辑到 ' + this.maxRows + ' 行以内再保存哦')
      }
      let preface = this.content || ''
      if (this.signature) {
        preface = [preface, this.signature].join('#signature#')
      }
      const data = {
        preface,
        type: this.book.type,
        bookType: this.book.bookType,
        sourceId: this.bookParams.sourceId
      }
      if (this.book.sourceTypeId) {
        data.sourceTypeId = this.book.sourceTypeId
      }
      try {
        this.saving = true
        // 博客书分开处理
        if (this.book.bookType !== 'blogbook') {
          await this.$req.post('/api/book/design?book_type=' + this.book.bookType, data)
        } else {
          await this.$ajax.updateBlogbook({bid: this.book.id, data})
        }
        this.$alert.success('序言保存成功')
        this.$router.push('/books/' + this.$route.params.bookId + '#preface')
      } catch (err) {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.preface {
  max-width: 27.5em;

  &[class*="A4"] {
    max-width: 35.5em;
  }

  textarea {
    padding-bottom: 2em;
  }

  .lines {
    float: left;
    font-size: 12px;
    margin-top: -2em;
    margin-left: 1em;
    color: $text-muted;
    display: inline-block;
    pointer-events: none;
  }

  .signature {
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background-color: transparent;

    &:focus {
      box-shadow: none;
    }
  }
}
</style>
