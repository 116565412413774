<template>
  <div :class="{selecting, selected, editing}" class="content-item card" :data-id="item.id">
    <div class="card-header">
      <b-row align-v="center">
        <b-col>
          <b v-if="editing && canEditTime">正在编辑</b>
          <datetime :value="item.time" full class="time" v-else/>
        </b-col>
        <b-col cols="auto">
          <template v-if="!item.id">
            <b-badge variant="warning">新补写</b-badge>
          </template>
          <template class="text-muted" v-else-if="selecting">
            <div class="text-muted" v-if="!selected">点击任意位置选中</div>
            <div class="text-primary" v-else>
              <fa icon="check"/>
              已选中
            </div>
          </template>
          <template v-else-if="canEdit">
            <template v-if="editing">
              <b-link :disabled="saving || uploading" @click="cancel">取消</b-link>
              &#12288;
              <b-link :disabled="!dirty || saving || uploading" @click="save" variant="success">
                <fa icon="spinner" spin v-if="saving"/>
                <fa icon="check" v-else/>
                保存
              </b-link>
            </template>
            <template v-else>
              <b-link variant="link" :disabled="saving" @click="edit">
                <fa icon="pencil"/>
                编辑
              </b-link>
              &#12288;
              <b-link variant="link" :disabled="saving" @click="deleteItem" class="text-body">
                <fa fw icon="spinner" spin v-if="saving"/>
                <fa fw icon="trash" v-else/>
                删除
              </b-link>
            </template>
          </template>
        </b-col>
      </b-row>
    </div>

    <div class="card-body">
      <div class="float-right mb-2" v-if="item.type === 'video' && !editing">
        <b-link @click="previewVideo">
          <fa icon="play" fas/>
          预览视频
        </b-link>
      </div>

      <div class="editor" v-if="editing">
        <date-time-picker class="mb-3" v-model="item.time" v-if="canEditTime"/>

        <textarea :disabled="disabled || !canEditText" class="form-control"
                  placeholder="写点什么吧..." v-autofocus
                  v-autosize v-model="item.text"></textarea>

        <div class="text-muted mt-2" v-if="!canEditText">
          <fa icon="exclamation-triangle"/>
          朋友的书无法修改文字哦
        </div>
      </div>

      <div class="text" v-else-if="item.text">{{item.text}}</div>

      <template v-if="item.type === 'video'">
        <upload-zone class="mt-2" readonly :value="urls" v-if="pics"/>
        <div class="mt-2">
          <fa icon="exclamation-triangle"/>
          小视频不支持修改图片哦
        </div>
      </template>

      <template v-else-if="editing || pics.length">
        <upload-zone ref="uploadZone"
                     :draggable="canSortPics"
                     contain
                     class="mt-3"
                     :max="maxPicsCount"
                     :readonly="!editing"
                     v-model="pics"/>
        <div class="mt-3" v-if="canSortPics && editing && pics.length > 1">
          <fa icon="arrows"/>
          拖动图片可调整位置哦
        </div>
      </template>

      <div class="comments" v-if="totalCommentCount">
        <header>
          <fa icon="comment-alt"/>
          共{{totalCommentCount}}条评论
          <span v-if="commentCount" class="ml-n2">（精选{{commentCount}}条）</span>
          <div class="float-right">
            <b-link @click="expanded = false" v-if="expanded">收起</b-link>
            <b-link @click="expandComments" v-else>展开</b-link>
          </div>
        </header>

        <div class="mt-3" v-if="expanded">
          <loading v-if="loading"/>
          <template v-else>
            <b-row class="comment" v-for="comment in comments" :key="comment.id"
                   :class="{hidden: comment.hidden}">
              <b-col class="pr-0 content">
                <span class="nickname mr-n1">{{comment.displayName}}：</span>
                {{comment.content}}
              </b-col>
              <b-col cols="auto">
                <b-link @click="toggleComment(comment)" class="toggler"
                        :class="{disabled: !editing}">
                  {{comment.hidden ? '显示' : '隐藏'}}
                </b-link>
              </b-col>
            </b-row>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { chain, cloneDeep, get, isEqual } from 'lodash'
import inherits from '@/mixins/inherits'

import UploadZone from './UploadZone'
import DateTimePicker from './DateTimePicker'

export default {
  name: 'contentItem',
  components: {DateTimePicker, UploadZone},
  mixins: [inherits(['book', 'bookParams', 'forgeData'])],
  props: {
    value: {
      type: Object,
      required: true
    },
    disabled: Boolean,
    selected: Boolean,
    selecting: Boolean
  },
  data() {
    return {
      recoverCommentIds: [],
      deleteCommentIds: [],
      comments: [],
      loading: false,
      original: null,
      expanded: false,
      active: false,
      saving: false
    }
  },
  computed: {
    item() {
      return this.value
    },
    totalCommentCount() {
      return get(this.item, 'stat.totalCommentsC') || 0
    },
    commentCount() {
      return get(this.item, 'stat.commentsC') || 0
    },
    canEdit() {
      return !this.selecting && !this.disabled && !!this.item.id
    },
    canEditTime() {
      return this.item.enableEdit
    },
    canSortPics() {
      return this.canEditText
    },
    canEditText() {
      if (this.isAgent) {
        return true
      }
      if (!this.book.own) {
        return this.item.enableEdit
      }
      return true
    },
    uploading() {
      return get(this.$refs.uploadZone, 'uploading')
    },
    editing() {
      return this.canEdit && this.active
    },
    pics: {
      get() {
        return this.item.pics || []
      },
      set(value) {
        this.item.pics = value
      }
    },
    urls() {
      return this.pics.map(item => item.src)
    },
    maxPicsCount() {
      return this.book.bookType === 'qbbbook' ? 20 : 9
    },
    dirty() {
      const textOld = get(this.original, 'text')
      const text = get(this.item, 'text')
      const picsOld = get(this.original, 'pics')
      const pics = get(this.item, 'pics')
      const timeOld = get(this.original, 'time')
      const time = get(this.item, 'time')

      return [
        !isEqual(textOld, text),
        !isEqual(picsOld, pics),
        !isEqual(timeOld, time),
        this.recoverCommentIds?.length > 0,
        this.deleteCommentIds?.length > 0
      ].some(Boolean)
    }
  },
  mounted() {
    return setTimeout(() => {
      if (Number(this.$route.query.msgId) === Number(this.item.id)) {
        this.$el.scrollIntoView()
        window.scrollBy(0, -64)
        this.$el.classList.add('active')
      }

      if (Number(this.$route.hash.slice(1)) === Number(this.item.id)) {
        this.$el.scrollIntoView()
        window.scrollBy(0, -64)
      }
    })
  },
  methods: {
    expandComments() {
      this.expanded = true
      if (!this.comments.length) {
        this.fetchComments()
      }
    },
    async fetchComments() {
      try {
        this.loading = true
        const url = `/api/book/${this.book.sourceId}/items/${this.item.id}/comments`
        this.comments = await this.$req.get(url)
        this.comments = this.comments.map(i => {
          return {
            id: i.commentId,
            displayName: i.displayName,
            hidden: !!i.hidden,
            content: i.content
          }
        })
      } finally {
        this.loading = false
      }
    },
    toggleComment(comment) {
      comment.hidden = !comment.hidden
      if (comment.hidden) {
        if (this.recoverCommentIds.includes(comment.id)) {
          this.recoverCommentIds = this.recoverCommentIds.filter(id => id !== comment.id)
        } else {
          this.deleteCommentIds.push(comment.id)
        }
      } else {
        if (this.deleteCommentIds.includes(comment.id)) {
          this.deleteCommentIds = this.deleteCommentIds.filter(id => id !== comment.id)
        } else {
          this.recoverCommentIds.push(comment.id)
        }
      }
      this.$forceUpdate()
    },
    previewVideo() {
      const posterSrc = get(this.pics, [0, 'src'])
      const videoSrc = get(this.pics, [1, 'videoSrc'])
      if (videoSrc) {
        this.$emit('preview-video', {src: videoSrc, poster: posterSrc})
      }
    },
    edit() {
      this.original = cloneDeep(this.item)
      this.active = true
    },
    cancel() {
      this.item.text = cloneDeep(this.original.text)
      this.item.pics = cloneDeep(this.original.pics)
      this.item.comments = cloneDeep(this.original.comments)
      this.active = false
    },
    async save() {
      try {
        if (!this.dirty) {
          return
        }

        this.saving = true
        const msg = {
          time: this.$day(this.item.time).toJSON(),
          msgId: this.item.id,
          text: this.item.text || '',
          addPics: [],
          addPicsSize: [],
          deletePics: [],
          deleteCommentIds: this.deleteCommentIds,
          recoverCommentIds: this.recoverCommentIds
        }

        if (this.original.pics && this.original.pics.length) {
          msg.deletePics = chain(this.original).get('pics')
            .filter(item => item.id)
            .filter(item => !this.pics.find(i => i.id === item.id))
            .map(item => item.id)
            .value()
        }

        if (this.pics) {
          msg.addPics = this.pics.filter(pic => !pic.id).map(pic => pic.src)
          msg.addPicsSize = this.pics.filter(pic => !pic.id).map(pic => {
            const {width, height} = pic
            if (width > 0 && height > 0) {
              return [width, height].join('x')
            }
            return ''
          })
          msg.sortedIds = this.pics.map(pic => pic.id || pic.src)
        }

        const data = this.forgeData('edit', msg)

        const result = await this.$req.post('/api/book/design/message', data)

        this.$alert.success('内容保存成功')
        this.active = false

        this.$set(this.item, 'stat.commentsC', this.comments.filter(i => !i.hidden).length)

        if (result.id) {
          this.item.time = this.$day(result.time * 1000).toJSON()
          this.item.pics = result.pics
          this.item.text = result.text
        }

        this.$emit('save', this.item)
      } finally {
        this.saving = false
      }
    },
    async deleteItem() {
      try {
        this.saving = true

        const data = this.forgeData('batch_delete', {
          month: this.$route.params.month,
          deleteMainItemIds: [this.item.id]
        })

        await this.$req.post('/api/book/design/message', data)

        this.$alert.success('操作成功，可在回收箱内恢复已删除内容')
        this.$emit('delete', this.item)
      } catch (err) {
        console.error(err.message)
      }
      this.saving = false
    }
  }
}
</script>

<style lang="scss" scoped>
.content-item {
  .card-header {
    border-bottom: 0;
    user-select: none;
  }

  &.selecting {
    cursor: pointer;
    user-select: none;
  }

  &.selected {
    border-color: $primary;
  }

  &.active {
    animation: blink .5s 2 forwards;
    border-color: lighten($primary, 20%);
  }
}

.comments {
  padding: 1rem;
  background-color: #f5f5f5;
  margin-top: 1rem;
  border-radius: $border-radius;

  .nickname {
    color: #8CACC5;
  }

  .comment {
    &.hidden {
      .content {
        color: $text-muted;
        text-decoration: line-through;
      }
    }
  }
}

.toggler {
  &.disabled {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.image-zone, .upload-zone {
  margin-top: 0;
  margin-bottom: 0;
}

.editor {
  margin-bottom: 1rem;
}

.time {
  font-weight: bold;
  color: $text-muted;
}

textarea {
  font-family: $font-family-monospace;
  word-break: break-all;
}

.text {
  white-space: pre-wrap;
  font-family: $font-family-monospace;
  @include line-clamp(8);
}
</style>
