<template>
  <transition name="fade">
    <b-modal class="write" :ok-disabled="!canSave"
             :title="'补写内容至《' + book.title + '》'"
             @cancel="close" @ok="save" cancel-title="取消"
             ok-title="保存" v-model="dialogActive">
      <div class="model-content">
        <date-time-picker v-model="newItem.time"></date-time-picker>

        <div class="small text-muted my-2 no-select">
          <fa class="text-danger" icon="clock"/>
          <span class="text-danger" v-if="!newItem.time">请选择正确的日期时间</span>
          <span v-else>记得从上方修改日期时间哦</span>
        </div>

        <textarea class="form-control" maxlength="2000" placeholder="点击添加文字内容"
                  rows="5" v-autofocus v-autosize
                  v-model="newItem.text" v-word-count>
        </textarea>

        <div class="small text-muted my-2 no-select">
          <fa icon="image" class="text-primary"/>
          每条最多 9 张图片，上传后可拖动调整顺序；为确保印刷质量，图片尺寸须大于 640 × 640
        </div>

        <upload-zone ref="uploadZone"
                     :max="maxPicsCount"
                     @finish="uploading = false"
                     @start="uploading = true"
                     draggable
                     v-model="newItem.pics"/>
      </div>
    </b-modal>
  </transition>
</template>

<script>
import DateTimePicker from '@/components/DateTimePicker'
import UploadZone from '@/components/UploadZone'

import bookUtils from '@/mixins/book-utils'
import routeData from '@/mixins/route-data'

export default {
  name: 'write',
  mixins: [routeData('book'), bookUtils],
  components: {DateTimePicker, UploadZone},
  computed: {
    maxPicsCount() {
      return this.book.bookType === 'qbbbook' ? 20 : 9
    },
    backUrl() {
      return '/books/' + this.$route.params.bookId
    },
    canSave() {
      if (!this.newItem.pics.length && !this.newItem.text) {
        return false
      }
      if (!this.newItem.time) {
        return false
      }
      return !this.uploading && !this.saving
    },
    dialogActive: {
      get() {
        return !!this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      writing: false,
      saving: false,
      uploading: false,
      newItem: {
        pics: [],
        text: '',
        time: ''
      }
    }
  },
  created() {
    const draft = this.$ls.get('draft')
    if (draft) {
      this.newItem.pics = draft.pics || []
      this.newItem.text = draft.text
      this.newItem.time = draft.time
      this.newItem.pics = this.newItem.pics.filter(pic => pic.src)
    }
  },
  methods: {
    async clear() {
      const confirmed = await this.$dialog.confirm({
        title: '清空',
        content: '是否要清空当前内容'
      })
      if (confirmed) {
        this.newItem.text = ''
        this.newItem.pics = []
        this.$ls.remove('draft')
      }
    },
    async save(event) {
      if (!this.canSave) {
        event.preventDefault()
        return this.$alert.warn('不能添加空内容哦')
      }

      const data = {
        action: 'add',
        text: this.newItem.text || '',
        time: this.$day(this.newItem.time).toJSON(),
        bookType: this.book.bookType,
        sourceId: this.bookParams.sourceId
      }

      if (!data.time) {
        event.preventDefault()
        this.$alert.warn('请选择正确的日期时间哦')
        return
      }

      if (this.book.sourceType) {
        data.sourceType = this.book.sourceType
      }

      if (this.bookParams.sourceTypeId) {
        data.sourceTypeId = this.bookParams.sourceTypeId
      }

      if (this.newItem.pics && this.newItem.pics.length) {
        data.pics = this.newItem.pics.map(item => item.src)
        data.picsSize = this.newItem.pics.map(pic => {
          const {width, height} = pic
          if (width > 0 && height > 0) {
            return [width, height].join('x')
          }
          return ''
        })
      }

      this.saving = true

      try {
        const result = await this.$req.post('/api/book/design/message', data)
        this.$alert.success('补写成功，您可以继续点击按钮进行补写哦')

        if (data.pics) {
          data.pics = data.pics.map(item => {
            return {
              url: item
            }
          })
        }

        if (result.time) {
          result.time = new Date(result.time * 1000)
        }

        this.$emit('save', result)

        this.newItem.text = ''
        this.newItem.pics = []

        this.$ls.remove('draft')
        this.close()
      } finally {
        this.saving = false
      }
    },
    close() {
      console.log('Close')
      this.dialogActive = false
    }
  },
  watch: {
    newItem: {
      handler(val) {
        if (val.text || val.pics.length) {
          this.$ls.set('draft', val)
        } else {
          this.$ls.remove('draft')
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.write {
  textarea {
    min-height: 6em;
    max-height: calc(100vh - #{360px});
  }
}
</style>
