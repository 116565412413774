<template>
  <div class="update-book">
    <h2 class="page-title">更新内容</h2>
    <loading v-if="loading"/>
    <template v-else-if="!book.canUpdate">
      <empty emotion="cry">
        当前作品不支持更新哦
        <b-btn variant="link" block @click="$router.go(-1)">返回</b-btn>
      </empty>
    </template>
    <template v-else-if="book.bookType === 'wxbook'">
      <b-card v-if="book.own">
        <square class="mx-auto" :src="qrcode" style="width: 60%; max-width: 180px;"/>
        <div class="mt-2">
          请使用微信添加小编
          <b>{{xiaobian}}</b>
          为好友
          <br>
          （如您已添加过，请向小编发送「更新」二字）
        </div>
        <div class="mt-2">
          或
          <b-link to="/assistant">使用小助手</b-link>
          更新私密内容及评论
        </div>
      </b-card>
      <b-card v-else>
        <p>
          <emotion value="study" size="200px"/>
        </p>
        <p>当前作品不是来自您本人的朋友圈，您需要在使用微信书小助手进行更新</p>
        <b-btn block to="/assistant" variant="primary">前往微信书小助手</b-btn>
      </b-card>
      <div class="btn-area">
        <b-btn block variant="link" @click="$router.go(-1)">返回</b-btn>
      </div>
    </template>
    <template v-else-if="book.bookType === 'wbbook'">
      <b-card>
        正在进入更新页面
        <br>
        请稍候...
      </b-card>
      <div class="btn-area">
        <b-btn block variant="link" @click="$router.go(-1)">返回</b-btn>
      </div>
    </template>
    <template v-else-if="book.bookType === 'shuoshuo_book'">
      <b-card>
        正在进入更新页面
        <br>
        请稍候...
      </b-card>
      <div class="btn-area">
        <b-btn block variant="link" @click="$router.go(-1)">返回</b-btn>
      </div>
    </template>
  </div>
</template>

<script>
import routeData from '@/mixins/route-data'
import bookUtils from '@/mixins/book-utils'

export default {
  name: 'updateBook',
  mixins: [routeData('book'), bookUtils],
  data() {
    return {
      qrcode: '',
      xiaobian: ''
    }
  },
  computed: {
    weiboAuthUrl() {
      return 'https://api.weibo.com/oauth2/authorize' + this.serialize({
        clientId: 1375343394,
        responseType: 'code',
        redirectUri: 'https://weixinshu.com/api/book/weibo_auth_callback',
        state: this.user.openid
      })
    }
  },
  methods: {
    async onLoad() {
      if (!this.book.canUpdate) {
        return
      }

      if (this.book.bookType === 'wxbook' && this.book.own) {
        return this.$router.replace({
          name: 'create.wxbook'
        })
      }

      if (this.book.bookType === 'wbbook') {
        if (!this.book.own || this.book.isAgentBook) {
          const {userScreenName, sourceId, id} = this.book
          return this.$router.replace({
            name: 'create.wbbookFriend',
            query: {
              nickname: userScreenName,
              bookId: id,
              uid: sourceId
            }
          })
        }
        if (this.book.own) {
          return this.$router.replace({
            name: 'create.wbbook'
          })
        }
      }

      if (this.book.bookType === 'shuoshuo_book') {
        const {sourceId} = this.book
        return this.$router.replace({
          name: 'create.qbook',
          query: {sourceId}
        })
      }

      if (this.book.bookType === 'qbbbook') {
        return this.$router.replace({
          name: 'create.qbbbook'
        })
      }

      if (this.book.bookType === 'bbsbook') {
        return this.$router.replace({
          name: 'create.bbsbook'
        })
      }
    }
  }
}
</script>

<style scoped>
  .update-book {
    max-width: 640px;
    text-align: center;
  }
</style>
